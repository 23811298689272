.customTable {
  text-align: center;
}

.customTable table {
  /* box-shadow: inset 0 0 4px 0px rgba(136, 152, 170, 0.15); */
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.02);
}

.customTable .table th,
.customTable .table td {
  vertical-align: middle;
  padding: 1.5rem 1rem;
  border-top: 0;
  /* border-bottom: 1px solid #e9ecef; */
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 16px;
}

.customTable .table th {
  /* color: #bfc5d2; */
  font-size: 14px;
  padding: 1rem 1.5rem;
}

/* .customTable .table .tableBody tr:last-child td {
  border-bottom: 0;
} */
.customTable .table .tableBody tr {
  background-color: #fff;
}

.link-btn {
  color: #007bff !important;
}

select,
select.form-control,
input.form-control {
  border-radius: var(--border-radius-default);
}

input[type="search"] {
  border-radius: 50px;
}

.chat-src input[type="search"] {
  border-radius: var(--border-radius-default);
}

.cardIcon {
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
  font-size: 1.25rem;
}

section .nav-tabs {
  border-bottom: 0;
}

section .nav-tabs .nav-link {
  position: relative;
  padding-left: 0;
  padding-right: 0;
}

section .nav-tabs .nav-link:nth-child(even) {
  margin-right: 2.25rem;
  margin-left: 2.25rem;
}

section .nav-tabs .nav-link.active,
section .nav-tabs .nav-link {
  background-color: transparent;
  border-color: transparent;
  color: #000;
}

section .nav-tabs .nav-link:focus,
section .nav-tabs .nav-link:hover {
  border-color: transparent;
}

section .nav-tabs .nav-link.active {
  font-weight: bold;
}

section .nav-tabs .nav-link.active::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: #f5b5a3;
  bottom: 0;
  max-width: 40%;
  min-width: 25px;
  border-radius: var(--border-radius-default);
}

.mwx-300 {
  max-width: 271px;
}

.mwx-400 {
  max-width: 400px;
}

.mwx-500 {
  max-width: 500px;
}

.mwx-60 {
  max-width: 60%;
}

.tabFilters {
  position: relative;
  top: var(--inputHeight);
  margin-top: -var(--inputHeight);
}

.selectfilters {
  top: var(--inputHeight);
  margin-top: calc(var(--inputHeight) * -1);
}

/* .selectfilters select {
  border-radius: 20px;
} */

.selectfilters .form-group {
  margin-bottom: 0;
}

.btn.btn-primary:hover,
.btn.btn-primary {
  height: auto;
  padding: 0.75rem 2rem;
  border-radius: 50px;
  background-color: #000000;
  border-color: #000000;
}

.btn.btn-primary:not(:disabled):not(.disabled):active,
.btn.btn-primary.focus,
.btn.btn-primary:focus {
  /* border-color: #f5b5a3;
  background-color: #f5b5a3; */
  /* color: #000; */
  background-color: #000000;
  border-color: #000000;
  box-shadow: none !important;
}

.btn.btn-secondary {
  padding: 0.75rem 2rem;
  border-radius: 50px;
}

.btn.btn-secondary:not(:disabled):not(.disabled):active,
.btn.btn-secondary.focus,
.btn.btn-secondary:focus {
  box-shadow: none !important;
}

.tabBody {
  margin-top: 40px;
}

.tabBody.sb400 input[type="search"] {
  min-width: 350px;
}

.tabBody.offsetSearch {
  margin-top: -90px;
}

.tabFilters input[type="search"] {
  min-width: 250px;
}

.search-label {
  margin-bottom: 0;
}

.searchIcon svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
}

.searchIcon input {
  padding-left: 40px;
}

.customTable .search-label input {
  min-width: 250px;
  border-radius: 50px;
  max-width: 250px;
}

.rightContent select,
.rightContent input {
  border-color: #f6f6f6;
  height: var(--inputHeight);
}

.modal-body select.form-control,
.modal-body input.form-control {
  height: var(--inputHeight);
}

button.btn svg,
a svg {
  color: #000;
  font-size: 20px;
  pointer-events: none;
}

td a {
  padding: 0.375rem 0.75rem;
}

div.card {
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.02);
  border-radius: var(--border-radius-default);
}

div.card-body {
  padding: 1.75rem 1.25rem;
}

label.required::after {
  content: "*";
}

.modal-header {
  background-color: #000;
  color: #fff;
}

.modal-header .close {
  opacity: 1;
}

.modal-header .close span {
  color: #fff;
  font-size: 30px;
  text-shadow: none;
}

.no-wrap {
  white-space: nowrap;
}

.inline-form div.col-auto {
  padding-right: 20px;
}

.inline-form label {
  white-space: nowrap;
  margin: 0;
  min-width: 150px;
}

.inline-form input,
.inline-form select {
  min-width: 250px;
}

.stepForm div > a,
.stepForm div > span,
.stepForm div > div {
  /* display: block; */
  line-height: 34px !important;
}

.modal-lg.big {
  max-width: 1000px;
}

.modal-lg.small {
  max-width: 600px;
}

hr.dark {
  border-color: #000;
}

.text-underline {
  text-decoration: underline;
}

.max-200 {
  max-width: 200px;
}

.max-300 {
  max-width: 300px;
}

.max-400 {
  max-width: 400px;
}

.rightContent.notFoundPage {
  display: flex;
  align-items: center;
}

.rightContent.notFoundPage .notFoundBox {
  max-width: 900px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
}

.rightContent.notFoundPage img {
  max-width: 400px;
  margin: 0 auto;
  display: block;
}

.text-active {
  position: relative;
  line-height: 2;
}

.text-active::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: #f5b5a3;
  bottom: 0;
  max-width: 50px;
  min-width: 25px;
  border-radius: var(--border-radius-default);
}

.form-m-0 .form-group {
  margin-bottom: 0;
}

.font-14 {
  vertical-align: middle;
  font-size: 14px !important;
}

.font-20 {
  vertical-align: middle;
  font-size: 22px;
}

.btn.btn-primary.disabled,
.btn.btn-primary:disabled,
.btn.btn-secondary:disabled,
.btn.btn-secondary:disabled {
  background-color: rgba(0, 0, 0, 0.2);
  border-color: rgba(0, 0, 0, 0.01);
}

.toastMessage {
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 9199991919;
}

.toastMessage .toast {
  background-color: #fff;
  color: #000;
  /* border-radius: 15px; */
}

.err-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.err-feedback.text-big {
  font-size: inherit;
}

select.form-control.is-invalid,
.was-validated select.form-control:invalid,
select.form-control.is-valid,
.was-validated select.form-control:valid {
  background-position: right calc(1.375em + 0.1875rem) center;
}

.imgUploadBox {
  width: 100%;
  height: 120px;
  border-radius: var(--border-radius-default);
  background-color: #fff;
  position: relative;
  overflow: hidden;
}

.imgUploadBox input {
  opacity: 0;
  user-select: none;
  position: absolute;
  cursor: pointer;
  /* background: red; */
  top: 0;
  bottom: 0;
  height: 100%;
  z-index: 9;
}

.imgUploadBox svg {
  color: #bababa;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 45px;
  z-index: 1;
  pointer-events: none;
}

.imgUploadBox::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 29px;
  height: 29px;
  background-color: #fff;
  z-index: 1;
  pointer-events: none;
}

.imgUploadBox small {
  position: absolute;
  top: calc(50% + 35px);
  transform: translateY(-50%);
  font-size: 14px;
  width: 100%;
  text-align: center;
}

.imgUploadBox + .err-feedback {
  position: absolute;
  top: calc(50% + 70px);
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  text-align: center;
}

.imgUploadBox img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.imageBoxContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.basic-multi-select .select__control {
  line-height: 1.5;
  color: #000;
  border-radius: var(--border-radius-default);
  min-height: var(--inputHeight);
  border: 1px solid #ced4da;
}

.basic-multi-select .select__multi-value {
  border-radius: 50px;
}

.basic-multi-select .select__multi-value__label {
  color: #000;
}

svg > path {
  pointer-events: none;
}

.workout_form [class^="col-md"] .form-group {
  position: relative;
}

.workout_form [class^="col-md"] .invalid-feedback {
  position: absolute;
  bottom: -25px;
}

.workout_form [id^="circuit_name"] + .invalid-feedback {
  position: absolute;
  bottom: -25px;
}

.modal-body .imgUploadBox {
  box-shadow: 0 0 15px rgb(0 0 0 / 0.1);
}

.cards_div p {
  margin-bottom: 0;
}

.searchIcon-minus {
  display: block;
  margin-bottom: -45px;
}

.datePickerBox input[type="text"] {
  min-width: auto;
  max-width: 117px;
  border-radius: var(--border-radius-default);
  border-color: transparent;
  outline: none;
  font-size: 14px;
  text-align: center;
}

div.react-confirm-alert-body {
  width: 450px;
}

div.react-confirm-alert-overlay {
  background: rgba(0, 0, 0, 0.5);
}

.char-len {
  position: absolute;
  right: 10px;
  bottom: 0px;
}

.thead-dark th {
  white-space: nowrap;
}

div .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #000;
  background-color: #000;
}

/* div .custom-control-label::before {
  border-color: #000;
} */
div.rounded {
  border-radius: var(--border-radius-default) !important;
}

/* div.spinner-border {
  border: 0.25em solid #f5b5a3;
  border-right-color: transparent;
} */

form.was-validated .form-check-input:valid ~ .form-check-label {
  color: #000;
}

textarea.form-control {
  padding-bottom: 15px;
}

/* section {
  padding: 40px 4%;
} */

div .form-control-plaintext {
  outline: none !important;
  border: 1px solid #ced4da;
  padding: 10px;
  border-radius: var(--border-radius-default);
}

.aboutUserPanel {
  /* height: calc(100vh - 2.6%); */
  height: calc(100vh - 80px);
  overflow-y: auto;
  /* border-left: 1px solid #000; */
  /* padding-left: 15px; */
  padding-left: 0;
  padding-right: 15px;
}

.max-450 {
  max-width: 450px;
}

.datepicker_dashboard,
.datepicker_dashboard:focus {
  border-right: none !important;
  border-left: none !important;
  border-top: none !important;
}

div.container,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  min-width: 1024px !important;
  padding: 0 !important;
}

.calender-placment {
  margin-left: -25px;
  margin-top: 2px;
  z-index: 1000;
}

.color-date {
  background: #ebebeb;
  width: 104%;
}

@media (min-width: 1200px) {
  div.container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 100%;
    padding: 0;
  }
}

.dateBox .input-group-append {
  margin-left: -30px;
}

.mealTrackerImage img {
  height: 90px;
  object-fit: cover;
  margin: 15px 0;
  background-color: #f1f3f5;
}

.mealTrackerImagePop img {
  height: 120px;
}

.mealTrackerImage .col-md-6 {
  position: relative;
}

.mealTrackerImage .col-md-6 .dt {
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 2;
  color: #fff;
  font-size: 12px;
}

.mealTrackerImage .label {
  position: absolute;
  right: 15px;
  top: 15px;
  background-color: #ececec;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding-left: 5px;
  font-size: 12px;
}

.mealTrackerImage .col-md-6 .dt-overlay {
  position: absolute;
  top: 15px;
  left: 15px;
  width: 70px;
  height: 18px;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

/* .mealTrackerImage .col-md-6 .dt::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: red;
  width: 90px;
  height: 20px;
  z-index: 1;
} */

.aboutUserPanel .items {
  /* border: 1px solid #000; */
  padding: 8px 12px;
  margin: 4px;
  display: inline-block;
  border-radius: var(--border-radius-default);
  font-weight: 600;
  background-color: #fdfdfd;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 5%);
}

.mtracker .container {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
  padding: 0 !important;
  width: 100% !important;
}

.mtracker .react-datepicker__input-container input {
  outline: none;
  border: none;
  box-shadow: none;
  border-radius: var(--border-radius-default);
  padding-left: 15px;
  width: 100%;
}

.prgImg img {
  min-width: 90px;
  max-width: 90px;
  height: 90px;
  object-fit: cover;
  background-color: #f1f3f5;
}

.prgImg label {
  min-width: 90px;
  max-width: 90px;
  font-size: 12px;
  text-align: center;
}

/* chat lists---------------------- */
.chat-user-list {
  position: relative;
  width: 95%;
}

.chat-user-list .activeUser {
  position: absolute;
  width: 8px;
  height: 8px;
  top: 8px;
  right: 8px;
  border-radius: 50%;
  background-color: #f5b5a3;
}

.chat-user-list .inactiveUser {
  display: none;
}

.chat-user-list div img {
  width: 40px;
  height: 40px;
  /* min-width: 50px; */
  /* min-height: 50px; */
  border-radius: 50%;
  object-fit: cover;
}

.chat_img_box {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.cursor {
  cursor: pointer;
}

/* -----------------userprofile */
.profile-pic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

/* chat css */

.right_sidebar_loading .spinner {
  position: relative;
  top: 0;
  left: 0;
  transform: translate(16%, 50%);
  margin: 0 auto;
}

.content {
  padding: 0px 0px;
  background: #fff;
  border-radius: 10px;
}

img {
  max-width: 100%;
}

.inbox_people {
  background: #f8f8f8 none repeat scroll 0 0;
  float: left;
  overflow: hidden;
  width: 40%;
  border-right: 1px solid #c4c4c4;
}

.inbox_msg {
  clear: both;
  overflow: hidden;
}

.top_spac {
  margin: 20px 0 0;
}

.channel_heading {
  float: left;
  width: 40%;
}

.srch_bar {
  display: inline-block;
  text-align: right;
  width: 60%;
}

.headind_srch {
  padding: 10px 29px 10px 20px;
  overflow: hidden;
  border-bottom: 1px solid #c4c4c4;
}

.channel_heading h4 {
  color: #05728f;
  font-size: 21px;
  margin: auto;
}

.srch_bar input {
  border: 1px solid #cdcdcd;
  border-width: 0 0 1px 0;
  width: 80%;
  padding: 2px 0 4px 6px;
  background: none;
}

.srch_bar .input-group-addon button {
  background: transparent none repeat scroll 0 0;
  border: medium none;
  padding: 0;
  color: #707070;
  font-size: 18px;
}

.srch_bar .input-group-addon {
  margin: 0 0 0 -27px;
}

.chat_ib h5 {
  font-size: 15px;
  color: #464646;
  margin: 0 0 8px 0;
}

.chat_ib h5 span {
  font-size: 13px;
  float: right;
}

.chat_ib p {
  font-size: 14px;
  color: #989898;
  margin: auto;
}

.chat_img {
  float: left;
  width: 11%;
}

.chat_ib {
  float: left;
  padding: 0 0 0 15px;
  width: 88%;
}

.chat_people {
  overflow: hidden;
  clear: both;
}

.chat_list {
  border-bottom: 1px solid #c4c4c4;
  margin: 0;
  padding: 18px 16px 10px;
}

.inbox_chat {
  height: 550px;
  overflow-y: scroll;
}

.active_chat {
  background: #ebebeb;
}

.incoming_msg_img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-size: cover;
  background-position: center center;
  color: #194047;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  background-color: #ffd662;
}

.incoming_msg {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.received_msg {
  padding: 0 15px;
  vertical-align: top;
  width: 85%;
  position: relative;
  display: inline-flex;
}

.received_withd_msg P {
  font-size: 14px;
  margin: 0;
  color: #888b95;
  line-height: 18px;
  padding: 5px 15px;
  text-align: right;
  background: #e2e2e2;
  border-radius: 50px;
}

.time_date {
  color: #747474;
  display: block;
  font-size: 12px;
  margin: 8px 0 0;
}

.mesgs {
  padding: 15px;
  width: 100%;
}

.mesgs .row p {
  color: #888b95;
}

.sent_msg {
  width: 85%;
  padding: 0 15px 0 0;
  position: relative;
  display: flex;
  justify-content: flex-end;
}

.sent_msg P {
  font-size: 14px;
  margin: 0;
  color: #232f3f;
  line-height: 18px;
  padding: 5px 15px;
  text-align: right;
  background: #ffd662;
  border-radius: 50px;
}

.outgoing_msg {
  overflow: hidden;
  display: flex;
  align-items: center;
}

.type_msg {
  border-top: 1px solid #c4c4c4;
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px 0 0 0;
}

.type_msg .image_fields {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-size: cover;
  background-position: center center;
  background-color: #888b95;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}

.type_msg .image_fields span {
  text-transform: uppercase;
  color: #ffffff;
  font-family: "proxima_novaregular";
}

.type_msg .input_fileds {
  width: 100%;
  /* padding: 0px 0px 0px 10px; */
  padding: 0;
}

.type_msg input {
  color: #4c4c4c;
  font-size: 13px;
  min-height: 36px;
  width: 100%;
  background: #fafafa;
  border: 1px solid #c8c7cc;
  border-radius: 100px;
  padding: 0 10px;
  outline: 0 !important;
}

.type_msgs {
  display: flex;
  align-items: center;
}

.type_msgs .image_fields {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-size: cover;
  background-position: center center;
  background-color: #888b95;
  border: 2px solid #fff;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}

.type_msgs .image_fields span {
  text-transform: uppercase;
  color: #ffffff;
}

.type_msgs p {
  color: #194047;
}

.msg_send_btn {
  border: medium none;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  font-size: 17px;
  height: 33px;
  position: absolute;
  right: 2px;
  top: 16px;
  width: 33px;
}

.messaging {
  padding: 0;
  overflow: hidden;
}

.msg_history {
  height: 100%;
  width: 100%;
  min-height: 200px;
  max-height: 86vh;
  padding-right: 15px;
  padding-bottom: 30px;
  overflow-y: auto;
  display: flex;
  flex-direction: column-reverse;
}

.msg_history_chat {
  height: 100%;
  width: 100%;
  min-height: 200px;
  max-height: 86vh;
  padding-right: 15px;
  padding-bottom: 30px;
  overflow-y: auto;
  /* display: flex;  */
  /* flex-direction: column-reverse;   */
}

.msg_history > div span.font-weight-bold,
.msg_history > div p.font-weight-bold {
  font-weight: 500 !important;
}

.msg_history li.message {
  list-style-type: none;
  padding: 10px 0px;
}

.msg_history li:last-child {
  border: 0;
}

/* .msg_history > div:first-child {
  margin-bottom: 0 !important;
} */
.msg_history > div:last-child {
  padding-top: 5px;
}

@keyframes topScroll {
  0% {
    bottom: -100%;
  }

  100% {
    bottom: 50px;
  }
}

.chat-profile-pic {
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

/* .chat-tracker-page {
  height: 89vh; */
/* overflow-y: hidden;
} */

.chat-tracker-page {
  width: 100% !important;
}

/* chat section ends */

.overlayButton {
  position: absolute;
  background: transparent;
  width: calc(100% - 30px);
  z-index: 2;
  height: calc(100% - 30px);
  top: 15px;
  left: 15px;
  border-radius: 0;
  cursor: pointer;
}

.notesBox {
  overflow-y: auto;
  max-height: 275px;
}

.notesBox p {
  margin-bottom: 0;
}

.notesBox h1,
.notesBox h2,
.notesBox h3,
.notesBox h4,
.notesBox h5,
.notesBox h6 {
  margin-bottom: 5px;
}

.msg_box {
  display: flex;
  max-width: 70%;
}

.msg_box_rec {
  justify-content: flex-end;
  padding: 10px;
  border-radius: 4px;
  background-color: #fdfdfd;
}

.msg_box_send {
  justify-content: flex-start;
  padding: 10px;
  border-radius: 4px;
  background-color: #ececec;
}

.customScrollBar::-webkit-scrollbar {
  width: 4px;
}

.customScrollBar::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 20px;
}

.customScrollBar::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 20px;
}

.customScrollBar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.textNoWrap {
  white-space: nowrap;
}

.chatNameBox {
  width: 40px;
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #000;
  color: #fff;
  font-weight: bold;
  display: grid;
  place-items: center;
  text-transform: capitalize;
}

.msg_box_send .chatNameBox {
  margin-left: 0.5rem;
}

.msg_box_rec .chatNameBox {
  margin-right: 0.5rem;
}

.fullHeight {
  min-height: calc(100vh - var(--topspace));
  max-height: calc(100vh - var(--topspace));
  overflow: hidden;
}

.fullHeight.custom-chat {
  width: 100% !important;
  padding: 0;
  margin: 0;
}

div.react-datepicker-popper {
  z-index: 3;
}

.font-black {
  color: #000;
}

div.top-auto {
  top: auto !important;
}

.notificationHeader {
  position: relative;
}

.nt-count {
  position: absolute;
  top: 4px;
  right: 8px;
  background: #f5b5a3;
  color: #fff;
  min-width: 24px;
  height: 24px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  text-align: center;
  /* padding: 6px 4px; */
  font-size: 12px;
  vertical-align: middle;
}

/* .notificationHeader .dropdown-menu::after {
  left: 14px;
} */

.notificationHeader .dropdown-item {
  max-width: 18rem;
  overflow: hidden;
  white-space: normal;
}

.notificationHeader .dropdown-menu ul li {
  padding: 0;
}

.notificationHeader .dropdown-menu ul li a {
  padding: 4px 8px;
  display: block;
}

.notificationHeader .dropdown-menu ul.list {
  min-height: 40px;
  max-height: 300px;
  overflow-y: auto;
}

.timeText {
  /* max-width: 100px; */
  /* overflow:visible; */
  font-size: 10px;
  float: right !important;
  /* justify-content: right !important; */
  /* text-align: end !important; */
  /* min-width: 99px;
  max-width: 100px;
  margin-top: -16px;
  margin-right: 10px; */
  /* text-overflow: ellipsis; */
  /* margin-right: 10px; */
}

.nt-count.hide {
  display: none;
}

.chat-user-list * {
  pointer-events: none;
}

.fromToErr {
  position: absolute;
  top: calc(100% - 2px);
}

.chatCount {
  position: absolute;
  left: 40px;
  top: 4px;
  font-size: 10px;
  background-color: #f5b5a3;
  width: 22px;
  height: 22px;
  padding: 2px;
  display: grid;
  place-items: center;
  vertical-align: middle;
  line-height: 1;
  color: #fff;
  border-radius: 50%;
}

.chatCount.hide {
  opacity: 0;
  visibility: hidden;
}

.addIcon_chat {
  width: 30px;
}

.userChatCount svg {
  font-size: 24px;
}

.userChatCount .nocount {
  display: none;
}

.userChatCount .count {
  position: absolute;
  top: -12px;
  right: 4px;
  font-size: 12px;
  background-color: #f5b5a3;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: grid;
  place-items: center;
  vertical-align: middle;
  line-height: 1;
  color: #fff;
}
.chat_list_head {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
}

@media (min-width: 1200px) {
  div.modal-xl {
    max-width: 100%;
  }

  .chat-user-lists {
    height: 100%;
    min-height: calc(100vh - 200px);
    max-height: calc(100vh - 200px);
    overflow: auto;
    /* padding-bottom: 120px; */
    padding-bottom: 50px;
  }
}

.typeTag {
  color: #fff;
  background-color: #1f75cb;
  border-radius: 10px;
  padding: 2px 10px;
  text-transform: capitalize;
  font-size: 12px;
  display: inline-block;
  cursor: pointer;
}

.typeTag[disabled] {
  cursor: default;
}

.chattimebx {
  vertical-align: middle;
  font-size: 13px;
}

.actionPills .pill {
  background-color: #ececec;
  border-radius: 10px;
  padding: 4px 10px;
  margin-top: 4px;
  margin-bottom: 4px;
  display: inline-block;
  cursor: pointer;
}

.actionPills .pill.active {
  background-color: #000;
  color: #fff;
}

.chatEncBox {
  justify-content: space-between;
  margin-bottom: 0.5rem;
  width: 60% !important;
}

.collImage img {
  object-fit: cover;
  height: 100%;
  min-height: 165px;
  max-height: 165px;
}

.collImage > div img + label {
  position: absolute;
  right: 0;
  top: 0;
  /* transform: translate(-50%, -100%); */
  margin: 0;
  background-color: #ececec;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 2px 12px;
  min-width: 63px;
  font-size: 12px;
  text-align: right;
}

.msgInb span {
  word-break: break-word;
  white-space: pre-line;
}

.userInsightBox .row .col {
  word-break: break-word;
}

.ReactModalPortal .ReactModal__Overlay {
  z-index: 999 !important;
  overflow-y: scroll !important;
}

.ReactModalPortal .ReactModal__Overlay ::-webkit-scrollbar-thumb {
  background: rgb(146, 143, 143);
}

.ReactModalPortal .ReactModal__Overlay ::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f5f5f5;
}

.ReactModalPortal .ReactModal__Overlay ::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

.chatTextBox.form-control {
  height: min-content;
  max-height: 100px;
  resize: none;
}

.chatTextBox.form-control:focus {
  box-shadow: none;
}

.chatTextBox.form-control:focus-visible {
  outline: none;
}

.chatTextBox {
  background: #f6f6f6 !important;
}

.type_msg .input-group {
  align-items: center;
  background-color: #fff;
  padding: 5px 0;
  border-radius: 8px;
}

button.delCht svg {
  font-size: 12px;
}

.pageNotFound.error {
  width: 550px;
}

.pageNotFound ul {
  list-style-type: disc;
}

/* paginationn New */

.pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
}

.pagination > .active > a {
  background-color: #f5b5a3;
  border-color: #f5b5a3;
  color: #fff;
}

.pagination > li > a {
  border: 1px solid #f5b5a3;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #f5b5a3;
  border-color: #f5b5a3;
  outline: none;
}

.pagination > li > a,
.pagination > li > span {
  color: black !important;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}

.search-register-user {
  width: 300px !important;
  margin-bottom: 0px !important;
}

.attach-file {
  width: 30px;
  height: 30px;
}

.img-chat {
  justify-content: center !important;
  align-items: center !important;
}

a {
  color: #0000ee !important;
}

.dots {
  cursor: pointer;
}

.chat-search {
  width: 237px !important;
  margin-right: 10px;
}

.chat_search_head {
  align-items: center;
  justify-content: right;
  margin-top: -20px;
}

.chat_search_label {
  margin-right: 10px;
  font-weight: 600;
}

.chat_logo_name {
  margin-top: 70px;
}

.chat_total_height {
  margin-top: -40px;
}

.check_chat {
  margin-left: 14px !important;
  margin-top: 10px;
  width: 5%;
}

.chat_list_box_1 {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.chat-img-box {
  max-width: 50px;
  min-width: 40px;
}

.chat_name_box {
  overflow-wrap: break-word;
  width: 85%;
}

.chat_icon_box {
  width: 25px;
}

.filter_chat_box {
  width: 122px !important;
  height: 40px !important;
  margin-top: 3px;
}

.img_chat_box {
  width: 22px !important;
  height: 22px !important;
  margin-right: 16px;
  margin-top: 8px;
  cursor: pointer;
}

.img_chat_box_drop {
  width: 15px !important;
  height: 15px !important;
  margin-right: 12px;
  margin-top: 12px;
  margin-left: -5px;
}

.chatbox_label {
  margin-top: 10px;
  margin-right: 12px;
  margin-left: 35px;
}

.redPin {
  width: 20px !important;
  height: 20px !important;
  margin-right: 4px;
  margin-left: 6px;
  margin-top: -2px;
}

.all_check_chat {
  margin-top: -10px !important;
  margin-left: 10px !important;
}

/* .chat-user-lists {
  max-height: 67vh !important;
} */

.name_label_chat {
  max-width: 60%;
  min-width: none;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.num_chat {
  width: 17px !important;
  height: 17px !important;
}

.LeftBox {
  width: 75% !important;
  background: #ffffff !important;
}

.chat_list_col {
  width: 40% !important;
}

.rightBox {
  width: 25% !important;
  background: #ffffff !important;
  padding-left: 4px;
  overflow-x: hidden;
}

.chat_bg {
  background: #f6f6f6;
}

.bg_2 {
  background: #f6f6f6 !important;
}

.container_chat {
  width: 100% !important;
  padding: 0 !important;
}

.custom-chat {
  width: 100% !important;
}

.chat_filter_msg_by {
  width: 50% !important;
  height: 40px !important;
  margin-right: 10px;
}

.label_chat_msg_by {
  font-size: 13px;
  font-weight: normal;
  margin-right: 10px;
  margin-top: 5px;
}

.chat_search {
  height: 37px !important;
  /* border-radius: 60px !important; */
}

.bg_pink {
  background: rgba(255, 232, 232, 0.3);
}

.redCircle {
  font-size: 10px;
  background: #d25151;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #ffffff;
}

.filter_right {
  display: flex;
  /* justify-content: right; */
  padding-right: 26px !important;
}

.user_level {
  color: red;
}

.user_name {
  font-weight: 600;
  font-size: 18px;
  font-style: normal;
}

.btn-link {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #0d09ff;
  text-decoration: underline !important;
  cursor: pointer;
}

.Card-header {
  background-color: white !important;
  border: 0px solid white !important;
}

.Card-header {
  background-color: white !important;
  border: 0px solid white !important;
}

.card-head {
  border-radius: 0px !important;
  border: none !important;
}

.health {
  font-weight: 500;
  font-style: normal;
  display: flex !important;
  background-color: rgb(255, 255, 255);
  justify-content: space-around;
}

.Card-header {
  background-color: white !important;
  border: 0px solid white !important;
  cursor: pointer;
}

.card-header Button::before {
  content: "" !important;
}

.card-header Button::after {
  background-color: red !important;
}

.none {
  font-weight: normal;
  font-style: normal;
  margin-top: 50px;
  text-align: center;
}

.notes {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  padding-left: 10px;
  margin-top: 15px;
}

.user-box-3 {
  width: 100%;
  border-radius: 10px;
  margin-right: 8px;
  margin-top: 15px;
  background-color: #ffffff;
  line-height: 30px;
}

.lorem {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
}

.img_chat_box_edit {
  float: right;
  cursor: pointer;
}

.userInsightBox p {
  margin-top: 20px;
}

@media (min-width: 760px) and (max-width: 1140px) {
  .userInsightBox {
    font-size: 12px;
  }
  .table-head-filters,
  .table-head-filter2 {
    display: block !important;
  }

  .redPin {
    width: 15px !important;
    height: 15px !important;
  }
  .chat_list_head > div:third-child {
    flex-basis: 100%;
    text-align: left;
  }
  .chat-user-lists {
    height: 100%;
    min-height: calc(100vh - 200px);
    max-height: calc(100vh - 200px);
    overflow: auto;
    /* padding-bottom: 120px; */
    padding-bottom: 50px;
  }
  .name_label_chat {
    font-size: 14px;
  }
  .chat-img-box {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    border-radius: 50%;
    object-fit: cover;
  }
  .chat-user-list div img {
    width: 20px;
    height: 20px;

    border-radius: 50%;
    object-fit: cover;
  }
}

.save {
  border-radius: 49px !important;
  background-color: #000000 !important;
  color: white !important;
  float: right;
  right: 0;
  width: 74px;
  height: 33px;
  margin-right: 15px;
  margin-top: 10px;
  padding: 0 !important;
  outline: none !important;
  border: none !important;
}

.copy_clipboard {
  border-radius: 49px !important;
  background-color: #000000 !important;
  color: white !important;
  float: right;
  right: 0;
  max-width: 100%;
  /* height: 33px; */
  /* margin-right: 15px; */
  /* margin-top: 10px; */
  padding: 10px !important;
  outline: none !important;
  border: none !important;
}

.textarea {
  padding: 20px 20px;
  box-sizing: border-box;
  border: none;
  height: 95%;
  border-radius: 4px;
  margin-top: 3px;
  background-color: #ffffff !important;
  font-size: 16px;
  outline: none;
}

.user-box-3 {
  height: 25%;
  position: relative;
  background-color: #ffffff !important;
  box-sizing: border-box;
  border: 1px solid rgb(0, 0, 0);
}

.user-box-3 img {
  position: absolute;
  top: -35px;
  right: 5%;
  cursor: pointer;
}

.myClass {
  background-color: rgb(109, 108, 108);
  color: red;
}

.btn-outline-secondary:hover {
  background-color: none;
}

.show > .btn-outline-secondary.dropdown-toggle {
  color: #3c3838;
  background-color: none;
}

.nav-tabs .nav-link {
  border: none !important;
  border-top-left-radius: none !important;
  border-top-right-radius: none !important;
}

.nav-tabs {
  border-bottom: none;
}

.diet-container {
  max-width: 700px;
  margin: 0 auto;
}

.diet-img {
  width: 300px;
  height: 180px;
}

.diet-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.diet-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
}

textarea {
  width: 100%;
  border: none;
  border-radius: none;
  box-sizing: border-box;
  background-color: #aba6a6;
  margin-top: 6px;
  margin-bottom: 10px;
  outline: none;
  /* height: 70px; */
}

.dish input[type="text"] {
  width: 320px;
  height: 40px;
  border: none;
  border-radius: none;
  box-sizing: border-box;
  background-color: #aba6a6;
  margin-top: 6px;
  margin-bottom: 10px;
  outline: none;
}

.nav-bar {
  display: flex;
  gap: 35px;
  margin: 20px;
}

.button-one {
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 700;
  color: #000;
  background-color: #fff;
  border: none;
  border-radius: 15px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
}

.button-one:focus {
  outline: none;
}

.water {
  text-decoration: none !important;
  color: #000 !important;
}

.steps {
  text-decoration: none !important;
  color: #000 !important;
}

.workout {
  text-decoration: none !important;
  color: #000 !important;
}

.diet {
  text-decoration: none !important;
  color: #000 !important;
}

.tracker {
  text-decoration: none !important;
  color: #000 !important;
}

.meal {
  text-decoration: none !important;
  color: #000 !important;
}

.Head {
  display: flex;
  justify-content: space-between;
}

/* .close {
  margin-top: 10px;
} */

/*{ My Waterintake css code }*/
.nav-one {
  background-color: #e5e5e5;
  margin: 5px 15px 10px 20px;
}

.points {
  margin: 30px;
  width: 95%;
  margin-bottom: 30px !important;
}

.point {
  margin: 30px;
  width: 95%;
  margin-bottom: 30px !important;
  margin-left: 90px;
}

.work {
  position: relative;
  width: 100%;
  padding-top: 2%;
}

.tracker {
  position: relative;
  width: 100%;
  padding-top: 2%;
}

.works {
  position: relative;
  max-width: 100%;
}

.worked {
  position: relative;
  width: 90%;
}

.intake {
  background-color: #c4c4c4;
  color: #000 !important;
  height: 30% !important;
}

.intakes {
  background-color: #c4c4c4;
  color: #000 !important;
  height: 30% !important;
}

.nav > a {
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #000000 !important;
}

.nav {
  background-color: #ffffff !important;
  border-bottom: 0px !important;
}

.nav > a.active {
  background-color: #c4c4c4 !important;
}

.nav > a:hover {
  border: none !important;
}

.program-lable {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  color: #000000;
}

.bg-gray {
  background-color: #c4c4c4;
}

.detail-modal {
  overflow-y: scroll;
}

.program-bg {
  background-color: #efecec;
}

.program-tab {
  position: relative;
  width: 100%;
}

/* .program-tab .nav-tabs .nav-link.active::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 4px;
    background-color: #f5b5a3;
    bottom: 0;
    max-width: 40%;
    min-width: 25px;
    border-radius: var(--border-radius-default);
}  */

.program-tab .nav-tabs a.active {
  border-bottom: 3px solid black !important;
  background-color: #efecec !important;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 0px !important;
}

.program-tab .nav-tabs .nav-link {
  background-color: #efecec !important;
}

.program-tab .nav > a {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #000000 !important;
}

.diet-lable {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
}

.graph-tabs .nav-tabs a.active {
  background-color: #fcf9f9 !important;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}

.graph-tabs .nav-tabs .nav-item {
  background-color: #c4c4c4 !important;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}

.graph-tabs .nav-tabs {
  background-color: #c4c4c4 !important;
}

.drop-down .dropdown .btn-success {
  background-color: #e9e6e6 !important;
  border-color: black !important;
  color: #000 !important;
}

.accordion > .card > .card-header .btn-link {
  text-decoration: none !important;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}

.user-lable {
  font-style: bold;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
}

.ReactModalPortal .ReactModal__Overlay::-webkit-scrollbar {
  width: 0;
  /* Remove scrollbar space */
  background: transparent;
  /* Optional: just make scrollbar invisible */
}

.custom-tooltip {
  background-color: #aba6a6;
  padding: 10px;
  text-align: center;
}

.custom-tooltip p {
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 21px;
}

.custom-tooltip .link {
  text-decoration: underline !important;
  font-weight: normal;
  font-size: 10px;
  line-height: 21px;
  margin: 0 !important;
}

.custom-tooltip::before {
  content: " ";
  position: absolute;
  top: 50%;
  right: 100%;
  /* To the left of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #aba6a6 transparent transparent;
}

.card-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
  align-items: center;
}

/* .user-lable{
  font-style: normal;
font-size: 14px;
line-height: 21px;
} */
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: none !important;
}

/* .card-content {
  margin-left: 30px;
} */
.user-box-2 {
  border: 1px solid rgb(0, 0, 0) !important;
  width: 100%;
  height: 130px;
}

.modal-backdrop {
  z-index: 100 !important;
}

.modal {
  z-index: 150 !important;
}

.graphModal .ReactModalPortal .ReactModal__Overlay {
  z-index: 999 !important;
  overflow-y: scroll !important;
  background-color: #000000 !important;
}

.modalHeader {
  color: #fff;
  height: 60px;
  background-color: #000000 !important;
}

.selectWeek {
  height: 40px;
  margin-right: 17px;
  border: none;
}

.secondaryBg {
  background: #ffffff !important;
}

.bgPrimary {
  background-color: #ffffff;
}

.bgTxt {
  background: #c4c4c4;
  border: none;
  width: 400px;
}

.diet_nf {
  background: #efecec;
  text-align: center;
  font-weight: 600;
  margin-top: 50px;
}

.Trackerhead {
  position: relative;
  top: 18px;
  font-size: large;
  margin-left: 20px;
}

.cursor {
  cursor: pointer;
}

.pm-height {
  height: 200px !important;
  width: 80%;
}

.max-margin {
  top: 350px;
}

.textarea {
  resize: none;
}

.smallModal {
  backdrop-filter: blur(5px);
  /* background-color: #01223770; */
  width: 400px;
}

/* .main_head{
  -webkit-filter: blur(1px);
  -moz-filter: blur(1px);
  -o-filter: blur(1px);
    -ms-filter: blur(1px);
    filter: blur(1px);
} */
/* .modal-open .container-fluid, .modal-open  .container {
  -webkit-filter: blur(1px);
  -moz-filter: blur(1px);
  -o-filter: blur(1px);
    -ms-filter: blur(1px);
    filter: blur(1px);
} */

/* .chartAreaWrapper {
  width: 1000px;
  overflow-x: scroll;
}

canvas {
width: 100% !important;
height: auto !important;
} */
canvas {
  /* width: 100vh !important; */
  height: 400px !important;
  overflow-x: scroll;
  cursor: pointer;
}

.clipboard {
  background: rgb(224, 221, 221);
  border-radius: 5px;
  padding: 5px;
  color: #007bff;
}

.copy_text {
  color: red;
  margin-left: 20px;
}

.hyperText {
  color: #007bff !important;
  text-decoration: underline;
}

.graph-gestures {
  cursor: pointer;
}

/*-------------------History Box--------------------- */
.history-header {
  display: flex;
  justify-content: space-between;
  padding: 0px 13px;
}

.History-heading-txt {
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
}

.view-txt {
  color: #0018f1;
  text-decoration: underline;
  font-size: 15px;
  font-weight: 500;
}

.history-desc {
  padding: 0px 10px;
  font-size: 14px;
  color: #929292;
}

.large-font {
  font-weight: 600;
  font-size: 20px;
}

.History-of-notes {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.History-of-notes p {
  margin-bottom: 0px !important;
}

.select-program-history {
  display: flex;
  gap: 10px;
  align-items: center;
}

.hide-select-program-history {
  display: none;
}

.notes-for-history .form-control:disabled,
.form-control[readonly] {
  background-color: #f6f6f6;
  opacity: 1;
  border-color: lightgray;
  font-size: 14px;
}

.notes-for-history textarea.form-control {
  height: auto;
  resize: none;
}

.select-background-color {
  background-color: #f6f6f6 !important;
  color: #000 !important;
  border: none !important;
}

.program-history-table {
  display: flex;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-bottom: 30px;
  font-size: 14px;
}

.program-history-table p {
  margin-bottom: 0px !important;
}

.general-information-col {
  /* background-color: #1f75cb; */
  border-right: 3px solid #000;
  min-width: 230px;
  max-width: 230px;
}

.current-program-table {
  /* background-color: #3c3838; */
  /* width: 800px; */
  display: flex;
  overflow-x: auto;
}

.odd-current-program-col {
  /* width: 250px; */
  /* min-width: 290px; */
  /* max-width: 230px; */
  background-color: #fff8f8;
  /* width: fit-content; */
  border-right: 3px solid #000;
}

.even-current-program-col {
  /* width: 250px; */
  /* min-width: 290px; */
  /* max-width: 230px; */
  background-color: #fff;
  /* width: fit-content; */
  border-right: 3px solid #000;
}
.remove-min-width {
  min-width: unset;
}
.add-min-width {
  min-width: 290px;
}

.start-and-end {
  display: flex;
  justify-content: space-around;
  /* background-color: #0018F1; */
}

.general-heading {
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
  padding: 5px 10px;
  white-space: nowrap;
}

.first-row {
  padding: 5px 0px;
  overflow: hidden;
}

.first-row > p {
  /* background-color: #00ee04; */
  padding: 8px 20px;
}

.start-column {
  border-right: 1px solid #e5e5e5;
}

.end-column {
  border-left: 1px solid #e5e5e5;
}

.light-weight {
  font-weight: 400;
}

.medium-weight {
  font-weight: 500;
}

.dark-weight {
  font-weight: 600;
}

.modal-header .close:active {
  outline: none !important;
}

.medical-condition {
  /* padding: 18px 20px!important; */
  height: 58px;
  display: flex;
  align-items: center;
}

.medical-condition-data {
  height: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*-----------------------------------------*/

.label-tooltip {
  position: relative;
  display: inline-block;
}

.label-tooltip .tool-tip-text {
  visibility: hidden;
  width: 100px;
  background-color: gray;
  color: #fff;
  text-align: center;
  font-size: 14px;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 40%;
  margin-left: -60px;
}

.label-tooltip .tool-tip-text {
  visibility: hidden;
  width: 110px;
  background-color: gray;
  color: #fff;
  text-align: center;
  font-size: 14px;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 40%;
  margin-left: -60px;
}

.label-tooltip .tool-tip-text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: gray transparent transparent transparent;
}

.label-tooltip:hover .tool-tip-text {
  visibility: visible;
}

.rmsc.nut-week-multi-select ul label {
  margin-bottom: 0px !important;
}

.rmsc.nut-week-multi-select .dropdown-container:focus-within {
  box-shadow: none !important;
  border: 1px solid #ccc !important;
  border-radius: 4px !important;
}

.rmsc.nut-week-multi-select ul input[type="checkbox"] {
  visibility: hidden;
  display: none;
}

.rmsc.nut-week-multi-select .dropdown-container {
  padding: 7px 0px;
}

.rmsc.nut-week-multi-select .dropdown-heading {
  height: fit-content;
  padding: 0px 0px;
  cursor: pointer;
}

.rmsc.nut-week-multi-select .clear-selected-button {
  padding: 5px;
}

.rmsc.nut-week-multi-select .dropdown-heading-dropdown-arrow {
  margin: 5px;
}

.flexValueWrapper {
  align-items: center;
  display: flex;
  flex: 1 1 0%;
  flex-wrap: wrap;
}

.waterWrapper {
  background-color: rgb(230, 230, 230);
  border-radius: 50px;
  display: flex;
  margin: 2px;
  min-width: 0px;
  box-sizing: border-box;
}

.watervalue {
  border-radius: 2px;
  color: #000;
  font-size: 85%;
  overflow: hidden;
  padding: 5px 5px 5px 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
}

.waterRemove {
  align-items: center;
  padding: 2px 5px;
}

.waterRemoveIcon {
  cursor: pointer;
  width: 15px;
  height: 15px;
}

.errormsg {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 16px;
  color: #dc3545;
}

.filterErrorMsg {
  border: 1px solid #dc3545 !important;
  border-radius: 4px;
}
.nameoverflow {
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.nodatatext {
  margin: 10rem 2rem 2rem 2rem;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}
.nodatatextgraph {
  margin: 5rem 2rem 5rem 2rem;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}
.text-align-right {
  text-align: right !important;
}

.mealImageErrorMsg {
  padding: 30px 0px;
  color: red;
  font-weight: 600;
  font-size: 17px;
}
.chat-data .font-weight-bold {
  font-weight: 500 !important;
}
.program-tab .nav-tabs {
  background-color: #efecec !important;
}
.table-head-filters,
.table-head-filter2 {
  display: flex;
  justify-content: space-between;
}
.table-head-filter2 {
  justify-content: flex-start;
}
.sync-btn{
  padding: 7px !important;
  border-radius: 4px !important;
  background: #000;
  color: white;
  
}
/*-------------------Form Inpuit--------------------- */
.form-control {
  /* min-width: 310px !important; */
}
.float-cus {
  float: right;
}
@media (min-width: 760px) and (max-width: 1024px) {
  .chat_list_head {
    width: 89%;
    justify-content: space-between;
  }
  .ReactModal__Content .ReactModal__Content--after-open {
    overflow-x: hidden;
  }
  .program-tab {
    margin: auto;
  }
  .responsive-header {
    font-size: 14px;
  }
  .nav {
    width: 100%;
    justify-content: space-between;
  }
  .nav > a {
    font-size: 12px;
  }
  .nav-link {
    padding: 0.5rem;
  }
  .program-lable {
    font-size: 24px;
  }
}
@media (max-width: 1024px) {
  .float-cus {
    float: left;
  }
}
@media (max-width: 1350px) {
  .row-cus {
    flex-direction: column;
  }
}
