:root {
  --topspace: 80px;
  --offset: 345px;
  --inputHeight: 49.55px;
  --ck-border-radius: 10px;
  --border-radius-default: 10px;
}

body {
  margin: 0;
  padding-top: var(--topspace);
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: "Poppins", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 1024px;
  color: #000;
  background-color: #f6f6f6;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a,
a:hover,
.btn.btn-link,
.btn.btn-link:hover {
  text-decoration: none;
  color: #000;
}
.mw-200 {
  min-width: 200px;
}
.mw-250 {
  min-width: 250px;
}
.mw-300 {
  min-width: 300px;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.dropdown-menu {
  padding: 0;
  border: none;
  box-shadow: 0 2px 25px rgba(0, 0, 0, 0.15);
}
.dropdown-menu ul li {
  padding: 8px 12px;
}
.dropdown-menu.min-14 {
  min-width: 14rem;
}

.dropdown-menu.min-16 {
  min-width: 16rem;
}

.dropdown-menu::after {
  content: "";
  height: 0;
  width: 0;
  border-bottom: 15px solid #fff;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  position: absolute;
  top: -13px;
  right: 14px;
}

.btn.btn-link:focus {
  box-shadow: none;
}

header.main {
  height: var(--topspace);
  display: flex;
  align-items: center;
  background-color: #fff;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 8;
}

header.main .logo {
  max-height: 40px;
  object-fit: contain;
}

.dashboardMain {
  display: flex;
  border-top: 0;
  min-height: calc(100vh - var(--topspace));
  width: 100%;
  /* margin-bottom: 50px; */
  /* margin: 50px 0; */
  padding: 0 !important;
  /* box-shadow: 0 0 15px rgba(21, 120, 162, 0.25); */
}
.dashboardMain aside {
  width: var(--offset);
  height: 100%;
  background: #fff;
  overflow: hidden;
  outline: none;
  /* border-right: 1px solid rgba(21, 120, 162, 0.1); */
  padding: 0;
  margin-top: 20px;
  padding-bottom: 140px;
  padding-right: 40px;
  position: fixed;
  background-color: #fff;
  z-index: 1;
  overflow-y: auto;
}
/* scrollbar */
.dashboardMain aside::-webkit-scrollbar {
  width: 4px;
}
.dashboardMain aside::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 20px;
}
.dashboardMain aside::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 20px;
}
.dashboardMain aside::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.sidebar ul {
  list-style: none;
  padding: 0;
}
.sidebar ul li a {
  width: 100%;
  display: block;
  padding: 20px 10px;
  padding-left: 20px;
  color: #000;
  position: relative;
}
.sidebar ul li a span {
  line-height: 1;
  vertical-align: middle;
}

.sidebar ul li a:hover {
  text-decoration: none;
}
.sidebar ul a.active,
.sidebar ul li a:hover {
  background-color: #f5b5a3;
  color: #000;
  text-decoration: none;
  border-radius: 0;
  box-shadow: none;
  font-weight: 600;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.sidebar ul a.active::before {
  content: url("./assets/images/left-arrow.svg");
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  line-height: 1;
  margin-top: 2px;
}
.sidebar img {
  max-width: 20px;
  object-fit: contain;
}

.sidebar ul li button {
  width: 100%;
  display: block;
  padding: 20px 10px;
  color: #000;
  position: relative;
  text-align: left;
  padding-left: 20px;
}

.sidebar .sidebarSubMenu a {
  padding-left: 55px;
}
/* right content */
.rightContent {
  /* margin-left: var(--offset); */
  width: 100%;
  max-width: 100%;
  overflow-y: auto;
  padding: 30px;
  /* padding-top: 0; */
  background: #f6f6f6;
  border-top-left-radius: 20px;
}

/* sign in */
.centerForm {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - var(--topspace));
  /* min-height: 550px */
  padding: 50px 0;
  /* background: url("./assets/bg.png"); */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-color: #f6f6f6;
}

.centerForm form {
  width: 100%;
  max-width: 500px;
  padding: 50px 25px;
  border: 2px solid rgba(21, 120, 162, 0.3);
  background-color: #fff;
  border-radius: var(--border-radius-default);
}

.centerForm form input {
  /* max-width: 80%;
  margin: 0 auto; */
  height: var(--inputHeight);
}
.centerForm form .form-group {
  max-width: 80%;
  margin: 0 auto;
  position: relative;
  padding-bottom: 4px;
}
.centerForm form .invalid-feedback {
  position: absolute;
  top: 50px;
  margin-top: 0;
}

/* side form */
.sideForm .form-group {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
}
.sideForm .form-group small {
  position: absolute;
  left: 42%;
  bottom: -20px;
  transform: translateX(-50%);
  color: #666;
}
.sideForm .form-group label {
  width: 45%;
  margin-bottom: 0;
}
.sideForm .form-control {
  margin-bottom: 0;
}

.mw200 {
  min-width: 200px;
}

.pageNotFound {
  width: 450px;
  max-width: 100%;
  margin: 0px auto;
  background: none;
  flex-direction: column;
}
.pageNotFound img {
  max-width: 80%;
  margin: 0 auto;
  display: block;
}
.pageNotFound h2 {
  font-size: 40px;
  margin-bottom: 35px;
}

.f-30 {
  font-size: 30px;
}
button.btn .f-30 {
  font-size: 30px;
}

#pageLoader {
  position: fixed;
  top: 50%;
  left: 50%;
  /* left: calc(50% + var(--offset) / 2); */
  transform: translate(-50%, -50%);
  z-index: 9991;
  display: none;
}

#pageLoader::before {
  content: "";
  width: 120vw;
  height: 120vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translate(-50%, -50%);
  /* background-color: rgba(0, 0, 0, 0.05); */
  background-color: transparent;
}
/* chat lists */
/* .chat-user-lists {
  height: 100%;
  min-height: -webkit-calc(100vh - 200px);
  max-height: -webkit-calc(100vh - 200px);
  overflow: auto;
  padding-bottom: 50px;
} */

.showHistoryTab {
  display: block;
}
.hideHistory {
  display: none;
}
.burger-menu {
  height: 5em;
  width: 4em;
  background-color: red !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  cursor: pointer;
}
.burger-bar {
  width: 4em;
  height: 0.5em;
  background: #f5b5a3;
  border-radius: 0.5em;
}
.menu {
  width: 50%;
  height: 100vh;
  background-color: aquamarine;
}
@media (max-width: 1126px) {
  .text-cus {
    font-size: 12px;
  }
}
